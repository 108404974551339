@media only screen and (min-width: 768px) {
  .Logo-top img {
    max-width: 600px;
  }
  .Banner-div {
    height: 31.5rem;
    background-image: url("/banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -150px;
  }
  .Half-width {
    flex-basis: 45%;
    margin: 1rem;
  }
  .Section-padding {
    padding: 10px 100px;
  }
  .Mid-Sections {
    display: flex;
    justify-content: space-between;
  }
  .Quarter-width {
    flex-basis: calc(25% - 30px);
    margin-right: 40px;
  }
  .Side-list {
    font-size: 1.25rem;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    padding-top: 67px;
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 768px) and (min-width: 401px) {
  .Logo-top img {
    max-width: 400px;
  }
  .Banner-div {
    height: 17rem;
    background-image: url("/banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -150px;
  }
  .Add-Wrap {
    flex-wrap: wrap;
  }
  .Half-width {
    flex-basis: 45%;
    margin: 1rem;
  }
  .Section-padding {
    padding: 5px 60px;
  }
  .Mid-Sections {
    display: flex;
    justify-content: space-between;
  }
  .Quarter-width {
    flex-basis: calc(25% - 30px);
    margin-right: 40px;
  }
  .Side-list {
    font-size: 1.25rem;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    padding-top: 67px;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 400px) {
  .Logo-top img {
    max-width: 300px;
  }
  .Banner-div {
    height: 10rem;
    background-image: url("/banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -150px;
  }
  .Add-Wrap {
    flex-wrap: wrap;
  }
  .Section-padding {
    padding: 20px;
  }
  .Half-width {
    margin-top: 2.5rem;
  }
  .Mid-Sections {
    justify-content: space-between;
  }
  .Side-list {
    font-size: 0.75rem;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    padding-top: 67px;
    padding-bottom: 10px;
  }
}

.error div {
  color: red;
}

.error input {
  border: 1px solid red;
}

.error textarea {
  border: 1px solid red;
}

.Blue-background {
  background-color: #d2edf8;
}

.Darkblue-background {
  background-color: #258dcb;
}

.Icon-size {
  font-size: 80px;
  color: #258dcb;
  position: absolute;
}

.App-header {
  position: relative;
}

.Logo-top {
  position: absolute;
  top: 2px;
  left: 30px;
}

.Header-div {
  min-width: 75%;
  display: flex;
  align-items: center;
}

#name {
  font-size: 2rem;
}

.Header-div div {
  margin-left: 5vh;
}

.Header-text {
  font-size: 1.95rem;
  flex-basis: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
}

.Contact-header {
  font-size: 1.95rem;
  flex-basis: 100%;
  margin-bottom: 15px;
}

.Side-list li {
  list-style: none;
  display: inline;
  margin-right: 30px;
  text-transform: uppercase;
  cursor: pointer;
}

.Side-list li:hover {
  color: #258dcb;
}

.Section {
  display: flex;
  flex-wrap: wrap;
}

.Section.Bottom-padding {
  display: block;
}

.Section-license {
  width: 100%;
  text-align: center;
  font-size: 1.25rem;
  padding-top: 15px;
  padding-bottom: 15px;
}

.Quarter-width:last-of-type {
  margin-right: 0px;
}

.Quarter-header {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

input {
  border: 1px solid #ccc;
}

.Box {
  display: flex;
  margin-bottom: 60px;
}

.Box-content {
  margin-left: 120px;
}

.Box-content strong {
  display: block;
  margin-bottom: 6px;
}

.Input {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  border: none;
  margin-top: 6px;
  font-family: "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 0.9rem;
}

.Input-large {
  width: 100%;
  box-sizing: border-box;
  border: none;
  padding: 5px;
  margin-top: 6px;
  font-family: "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 0.9rem;
}

#city {
  width: 54%;
  margin-right: 1.3rem;
}

#state {
  padding: 0.2rem;
  width: 40%;
}

#state-label {
  margin-left: 5.2rem;
}

.city-state-element {
  display: flex;
  justify-content: space-between;
}

.city-state {
  justify-content: space-around;
}

.city-state-label {
  display: flex;
  flex-direction: row;
}

.Form-label-sub {
  font-size: 0.5rem;
  margin-top: 0.2rem;
  flex: 1;
}

#stateLabel {
  margin-left: 6rem;
}

.Inline {
  margin-bottom: 0.2rem;
}

.Bottom-header {
  font-size: 1.95rem;
  flex-basis: 100%;
}

.Bottom-padding {
  padding: 10px 10vw 10px;
}

.Contact-info {
  margin-bottom: 15px;
  margin-right: 40px;
}

#form-sent {
  margin-top: 15px;
  color: green;
}

.Button {
  margin-top: 15px;
  padding: 6px 15px;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.Button:hover {
  background-color: #333;
}

.Button-container {
  display: flex;
  justify-content: space-between;
}

.App-link {
  color: #61dafb;
}

.Footer {
  text-align: center;
  font-size: 10px;
  padding-bottom: 30px;
}

.Payment-Types {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Payment-img {
  max-height: 2.5rem;
  margin-right: 2rem;
}

.Association-img {
  max-height: 60px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
