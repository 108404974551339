body {
	color: #333333;
	font-size: 16px;
  margin: 0;
  font-family: "Helvetica Neue", "Helvetica",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
	color: #258dcb;
}

a:hover {
	color: #333;
}

ul{
	padding: 0px 0px 0px 18px;
	margin: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
